body {
	font-family: SFRounded, ui-rounded, "SF Pro Rounded", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}
.container {
  max-width: 1200px;
}

[data-rk] {
	--rk-radii-connectButton: 999px !important;
}
